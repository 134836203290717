<!--suppress AngularUndefinedBinding --> <!-- suppression needed because `touchstart` is not recognized -->
<div class="orbitControls">Select an orientation...
  <button pButton type="button" icon="fas fa-chevron-left"
          (touchstart)="tapChangeOrientation($event, 1, 0)" (click)="changeOrientation(1, 0)"></button>&nbsp;
  <span class="spacer" [style.display]="rotation_xz < 0 ? 'none' : 'inline'">-</span>{{rotation_xz | number: '3.1-1'}}&deg;&#x2008;&nbsp;
  <button pButton type="button" icon="fas fa-chevron-right"
          (touchstart)="tapChangeOrientation($event, -1, 0)" (click)="changeOrientation(-1, 0)"></button>&nbsp;&#x2008;&nbsp;,&nbsp;&nbsp;
  <button pButton type="button" icon="fas fa-chevron-down"
          (touchstart)="tapChangeOrientation($event, 0, -1)" (click)="changeOrientation(0, -1)"></button>&nbsp;
  <span class="spacer" [style.display]="rotation_yz < 0 ? 'none' : 'inline'">-</span>{{rotation_yz | number: '3.1-1'}}&deg;&#x2008;&nbsp;
  <button pButton type="button" icon="fas fa-chevron-up"
          (touchstart)="tapChangeOrientation($event, 0, 1)" (click)="changeOrientation(0, 1)"></button>
  ...or drag view below.&nbsp;
  <button pButton type="button" (click)="resetOrientation()" label="Reset to 0&deg;"
    [disabled]="rotation_xz === 0 && rotation_yz === 0"></button>
</div>
<div #canvasWrapper class="canvasWrapper">
  <div class="canvasAnchor">
    <canvas #orbitCanvas (window:resize)="onResize()"
            (touchstart)="onTouchStart($event)" (mousedown)="onMouseDown($event)"
            (touchmove)="onTouchMove($event)" (mousemove)="onMouseMove($event)"
            (touchend)="onTouchEnd($event)" (touchcancel)="onTouchEnd($event)" (mouseup)="onMouseUp($event)"
            (wheel)="onWheel($event)"
            [style.cursor]="cursor"
    ></canvas>
  </div>
</div>
<ks-marquee class="marquee" [text]="'\u00A0' + marqueeText"></ks-marquee>
