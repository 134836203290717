<fieldset>
  <legend>Location Settings</legend>
  <div class="lat-lon-zone">
    <div class="lat-lon">
      <div class="latlong-values">
        <tbw-angle-editor [options]="latitudeStyle" [(ngModel)]="latitude"></tbw-angle-editor>
        <tbw-angle-editor [options]="longitudeStyle" [(ngModel)]="longitude"></tbw-angle-editor>
      </div>
      <div class="latlong-captions">
        <div class="latlong-caption">latitude</div>
        <div class="latlong-caption">longitude</div>
      </div>
    </div>
    <svc-zone-selector [(ngModel)]="zone"></svc-zone-selector>
    <hr>
    <ks-dropdown [options]="locationNames" [(ngModel)]="locationName" class="constrained-dropdown"></ks-dropdown>
    <div class="find-save">
      <button pButton type="button" label="Find" (click)="openFindDialog()"></button>
      <button pButton id="save-button" type="button" label="Save" (click)="openSaveDialog()"
          pTooltip="You can save newly found locations for future use." tooltipPosition="bottom"
          [tooltipDisabled]="!showSaveHint" tooltipStyleClass="save-tooltip" [life]="8000"></button>
      <button pButton type="button" label="Delete" (click)="openDeleteDialog()"></button>
    </div>
  </div>
  <p-dialog header="Save Location" [modal]="true" [(visible)]="showSaveDialog" ksSizer="350">
    <div style="height: 80px;">
      Save location as:&nbsp;
      <ks-dropdown #saveNameDropdown [(ngModel)]="selectedName" [options]="saveDialogNames" [editable]="true" ksSizer="200"></ks-dropdown>
      <br><br><p-checkbox [(ngModel)]="makeDefault" binary="true" label="Make this the default location"></p-checkbox>
    </div>
    <p-footer>
      <div class="p-dialog-buttonpane p-widget-content p-helper-clearfix">
        <button type="button" pButton icon="far fa-window-close" (click)="showSaveDialog=false" label="Cancel"></button>
        <button type="button" pButton icon="fas fa-check" (click)="doSave()" label="OK" [disabled]="!selectedName.trim()"></button>
      </div>
    </p-footer>
    <p-confirmDialog key="save-confirm" header="Same location name in use" icon="fas fa-question-circle" ksSizer="400" appendTo="body"></p-confirmDialog>
  </p-dialog>
  <p-dialog header="Delete Location" [modal]="true" [(visible)]="showDeleteDialog" ksSizer="350">
    <div style="height: 35px;">
      <div class="delete">
        <span>Location to delete:&nbsp;</span>
        <ks-dropdown #deleteNameDropdown [(ngModel)]="selectedName" [options]="deleteDialogNames" ksSizer="200"></ks-dropdown>
      </div>
    </div>
    <p-footer>
      <div class="p-dialog-buttonpane p-widget-content p-helper-clearfix">
        <button type="button" pButton icon="far fa-window-close" (click)="showDeleteDialog=false" label="Cancel"></button>
        <button type="button" pButton icon="fas fa-check" (click)="doDelete()" label="OK"></button>
      </div>
    </p-footer>
  </p-dialog>
  <svc-atlas-dialog [(visible)]="showFindDialog"></svc-atlas-dialog>
</fieldset>
