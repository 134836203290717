<!--suppress AngularUndefinedBinding --> <!-- suppression needed because `touchstart` is not recognized -->
<div #canvasWrapper class="canvasWrapper">
  <div class="canvasAnchor">
    <canvas #orbitCanvas (window:resize)="onResize()"
            (touchstart)="onTouchStart($event)"
            (touchmove)="onTouchMove($event)" (mousemove)="onMouseMove($event)"
            (touchend)="onTouchEnd($event)" (touchcancel)="onTouchEnd($event)"
            (wheel)="onWheel($event)"
            [style.cursor]="cursor"
    ></canvas>
  </div>
</div>
<ks-marquee class="marquee" [text]="'\u00A0' + marqueeText"></ks-marquee>
