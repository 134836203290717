<svc-change-location-dialog [(visible)]="showLocationDialog"
                            [latitude]="latitude" [longitude]="longitude" [timezone]="timezone"></svc-change-location-dialog>
<div #canvasWrapper class="canvasWrapper">
  <div class="canvasAnchor">
    <canvas #mapCanvas (window:resize)="onResize()"
            (mousemove)="onMouseMove($event)"
            (click)="onClick($event)"
            [style.cursor]="cursor"
    ></canvas>
    <div [style.display]="showTimezones ? 'block' : 'none'">
      <img #zoneOverlay [src]="zoneImageUrl" alt="timezone map">
    </div>
  </div>
</div>
<ks-marquee class="marquee" [text]="'\u00A0' + marqueeText"></ks-marquee>
