<div>
  <ks-checkbox [(ngModel)]="northOnTop" binary="true" label="North on top"></ks-checkbox>
  <ks-checkbox [(ngModel)]="eastOnLeft" binary="true" label="Celestial east on left"></ks-checkbox>
  <ks-checkbox [(ngModel)]="moonNumbers" binary="true" label="Moon numbers"></ks-checkbox>
  <ks-checkbox [(ngModel)]="moonNames" binary="true" label="Moon names"></ks-checkbox>
  <ks-checkbox [(ngModel)]="photoPlanets" binary="true" label="Photographic planets"></ks-checkbox>
  <ks-checkbox [(ngModel)]="markGrs" binary="true" label="Mark GRS center"></ks-checkbox>
  <div class="grs">
    <ks-checkbox [(ngModel)]="grsOverride" binary="true" label="Set GRS long.:"></ks-checkbox>
    <tbw-angle-editor style="flex: 0 0 auto" [options]="{ angleStyle: DDD, decimalPrecision: 1 }" [(ngModel)]="fixedGrs" [disabled]="!grsOverride"></tbw-angle-editor>
  </div>
  <div class="zoom">
    <p-slider [(ngModel)]="reverseZoom" [min]="0" [max]="zoomSteps"></p-slider>
    <button pButton type="button" class="default-zoom" label="&bull;" (click)="setDefaultZoom()"></button>
  </div>
  <div style="flex: 0 0 auto" class="zoom-caption">zoom</div>
</div>
