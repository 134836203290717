<div>
  <ks-dropdown [options]="spans" [(ngModel)]="span25"></ks-dropdown>
  <ks-dropdown [options]="orientations" [(ngModel)]="northOutward"></ks-dropdown>
  <p-dropdown [options]="additionals" [(ngModel)]="additional" [filter]="true" filterBy="search"
    [resetFilterOnHide]="true" emptyFilterMessage="No match"
    [disabled]="!asteroidsReady" class="constrained-dropdown" [autoDisplayFirst]="false"></p-dropdown>
  <ks-checkbox [(ngModel)]="ecliptic" binary="true" label="Ecliptic grid"></ks-checkbox>
  <ks-checkbox [(ngModel)]="celestial" binary="true" label="Celestial equator"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showConstellations" binary="true" label="Constellations"></ks-checkbox>
  <ks-checkbox [(ngModel)]="localHorizon" binary="true" label="Local horizon"></ks-checkbox>
  <div style="flex: 0 0 auto">
    <ks-checkbox [(ngModel)]="showStars" binary="true" label="Stars"></ks-checkbox>&nbsp;&nbsp;
    <ks-checkbox [(ngModel)]="brightenStars" binary="true" [disabled]="!showStars" label="Brighten stars"></ks-checkbox>
  </div>
  <ks-checkbox [(ngModel)]="topocentricMoon" binary="true" label="Topocentric Moon"></ks-checkbox>
  <ks-checkbox [(ngModel)]="enlargeSunMoon" binary="true" label="Enlarge Sun/Moon"></ks-checkbox>
</div>
<p-menu #menu [popup]="true" [model]="namesCategories"></p-menu>
<br>
<button type="button" pButton icon="fas fa-list" label="Show Names..." (click)="menu.toggle($event)"></button>
