<table>
  <tr>
    <th colspan=2 class="local-time">{{formattedLocalTime}}</th>
  </tr>
  <tr>
    <td>Universal Time (UT):</td>
    <td class="value">{{formattedUt}}</td>
  </tr>
  <tr>
    <td>Greenwich Apparent Sidereal Time (GAST):</td>
    <td class="value">{{formattedGast}}</td>
  </tr>
  <tr>
    <td>Local (Apparent) Sidereal Time (LST):</td>
    <td class="value">{{formattedLst}}</td>
  </tr>
  <tr>
    <td>Local Mean Time (at {{lmtLongitude}}):</td>
    <td class="value">{{formattedLmt}}</td>
  </tr>
  <tr>
    <td>Local Apparent Solar Time (00:00 = midnight):</td>
    <td class="value">{{formattedSolarTime}}</td>
  </tr>
  <tr>
    <td>ΔT (TDT - UT):</td>
    <td class="value">{{deltaT}}</td>
  </tr>
  <tr>
    <td>ΔTAI (TAI - UTC)<span *ngIf="!taiToUtcWellDefined" class="footnote">*</span>:</td>
    <td class="value">{{deltaTai}}</td>
  </tr>
  <tr>
    <td>Julian date:</td>
    <td class="value">{{formattedJulianDate}}</td>
  </tr>
  <tr>
    <td>Modified Julian date:</td>
    <td class="value">{{modifiedJulianDate}}</td>
  </tr>
  <tr>
    <td>Julian ephemeris date:</td>
    <td class="value">{{formattedEphemerisDate}}</td>
  </tr>
  <tr>
    <td>Modified Julian ephemeris date:</td>
    <td class="value">{{modifiedEphemerisDate}}</td>
  </tr>
  <tr>
    <td>Unix days:</td>
    <td class="value">{{formattedDays}}</td>
  </tr>
  <tr>
    <td>Unix seconds:</td>
    <td class="value">{{formattedSeconds}}</td>
  </tr>
  <tr *ngIf="!taiToUtcWellDefined">
    <td colspan=2 class="full-footnote">*Transitions to TAI - UT outside of well-defined range for UTC.</td>
  </tr>
</table>
