<!--suppress HtmlFormInputWithoutLabel, HtmlUnknownAttribute, TypeScriptValidateJSTypes, TypeScriptUnresolvedVariable -->
<p-dialog header="Find Latitude, Longitude, and Timezone" [modal]="true" [(visible)]="visible" [style]="{width: '797px'}"
          (keyup)="onKey($event)">
  <div style="position: relative">
    <div class="search-controls">
      <div class="top-caption-row">
        <span>City/place name -or- ZIP/postal code</span><span>US state -or- Canadian province -or- country</span>
        <span></span>
      </div>
      <div class="search-row">
        <span><input #searchField type="text" pInputText class="city-field" [(ngModel)]="city" [disabled]="searchInputDisabled"
                 (focus)="searchFocus(true)" (blur)="searchFocus(false)" (input)="searchChanged()"
                 autocorrect="off" autocapitalize="off" autocomplete="off"/></span>
        <span><ks-dropdown id="state-select" [(ngModel)]="state" [options]="states" [editable]="true" ksSizer="19em"></ks-dropdown></span>
        <span><button type="button" pButton icon="fas fa-search" (click)="search()" label="Search" [disabled]="searching || becomingVisible"
                      (focus)="searchFocus(true)" (blur)="searchFocus(false)"></button></span>
      </div>
      <div class="bottom-caption-row">
        <span>Required</span><span>(Optional)</span><span></span>
      </div>
      <p-checkbox [(ngModel)]="extended" class="ext-search" binary="true" label="Extended search (slower, possibly more matches)"></p-checkbox>
      <div class="busy" [style.display]="busy ? 'flex' : 'none'" [class.live-search]="!searchInputDisabled">
        <div><i class="fas fa-spinner fa-pulse fa-2x fa-fw"></i><span>&nbsp;&nbsp;&nbsp;Searching...</span></div>
      </div>
    </div>
  </div>
  <div class="table-wrapper">
    <div class="table-border">&nbsp;</div>
    <div *ngIf="!busy && !searching && (!locations || locations.length === 0)" class="table-overlay">&nbsp;</div>
    <p-table #locationTable [value]="locations" selectionMode="single" [scrollable]="locations?.length > 11"
             scrollHeight="300px" sortMode="none" [(selection)]="selection" metaKeySelection="true" class="results-table">
      <ng-template pTemplate="colgroup">
        <colgroup>
          <col style="width: 40px">
          <col style="width: 200px; max-width: 200px; overflow: hidden; text-overflow: ellipsis;">
          <col style="width: 55px">
          <col style="width: 62px">
          <col style="width: 74px">
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>&#x2605;</th>
          <th>Name</th>
          <th>Lat.</th>
          <th>Long.</th>
          <th>Timezone</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-loc>
        <tr [pSelectableRow]="loc" (dblclick)="onDoubleClick()">
          <td>{{(loc.rank || 0).toString(16).toUpperCase()}} <img src="assets/resources/flags/{{loc.flagCode}}.png" class="flag-image" width="16" height="11" alt="{{loc.flagCode}}"></td>
          <td style="max-width: 200px; text-overflow: ellipsis; overflow: hidden"><span [pTooltip]="showTooltip(loc.name)">{{loc.name}}</span></td>
          <td>{{loc.lat}}</td>
          <td>{{loc.lon}}</td>
          <td><span [pTooltip]="showTooltip(loc.zone)">{{loc.zoneInfo}}</span></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr><td colspan="5">{{emptyMessage}}</td></tr>
      </ng-template>
    </p-table>
  </div>
  <div class="map-wrapper">
    <div #atlasMap class="atlas-map"></div>
    <div class="map-mask" [ngStyle]="{visibility: maskDisplay}"></div>
  </div>
  <p-footer>
    <div class="p-dialog-buttonpane p-widget-content p-clearfix">
      <button type="button" pButton icon="far fa-window-close" (click)="visible=false" label="Cancel"></button>
      <button type="button" pButton icon="fas fa-check" (click)="setLocation()" label="OK" [disabled]="searching || !selection"></button>
    </div>
  </p-footer>
  <p-toast key="general" position="top-right"></p-toast>
</p-dialog>
