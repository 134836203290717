<!--suppress CheckImageSize -->
<div>
  <ks-dropdown [options]="extents" [(ngModel)]="extent"></ks-dropdown>
  <ks-dropdown [options]="centering" [(ngModel)]="centerEarth"></ks-dropdown>
  <ks-dropdown [options]="unitsChoices" [(ngModel)]="marqueeUnits"></ks-dropdown>
  <p-dropdown [options]="additionals" [(ngModel)]="additional" [filter]="true" filterBy="search"
    [resetFilterOnHide]="true" emptyFilterMessage="No match"
    [disabled]="!asteroidsReady" class="constrained-dropdown" [autoDisplayFirst]="false"></p-dropdown>
  <ks-checkbox [(ngModel)]="showPaths" binary="true" label="Mean orbits"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showMarkers" binary="true" label="Orbital markers"></ks-checkbox>
  <div class="anaglyph">
    <ks-checkbox [(ngModel)]="anaglyph3d" binary="true" label="Anaglyph 3-D"></ks-checkbox>
    <div class="anaglyph-radio">
      <p-radioButton name="anaglyphColors" [value]="true" [(ngModel)]="anaglyphRC" [disabled]="!anaglyph3d"></p-radioButton>
      <img src="/assets/resources/anaglyph-rc.png" width="38" height="14" alt="anaglyph red-cyan" class="glasses-3d"
           [ngClass]="{'faded': !anaglyph3d}" (click)="anaglyph3d && setAnaglyphRC(true)"><br>
      <p-radioButton name="anaglyphColors" [value]="false" [(ngModel)]="anaglyphRC" [disabled]="!anaglyph3d"></p-radioButton>
      <img src="/assets/resources/anaglyph-gm.png" width="38" height="14" alt="anaglyph green-magenta" class="glasses-3d"
           [ngClass]="{'faded': !anaglyph3d}" (click)="anaglyph3d && setAnaglyphRC(false)">
    </div>
  </div>
  <ks-checkbox [(ngModel)]="grayOrbits" binary="true" label="Gray orbits below horizon" [disabled]="anaglyph3d"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showNames" binary="true" label="Planet names"></ks-checkbox>
  <p-slider [(ngModel)]="reverseZoom" [min]="0" [max]="200"></p-slider>
  <div class="zoom-caption">zoom</div>
  <div [hidden]="!showMarkers" class="marker-legend">
    &#x00D7; Perihelion<br>
    + Aphelion<br>
    &#x25CB; Ascending node<br>
    &#x25A1; Descending node
  </div>
</div>
