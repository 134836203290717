<!--suppress CheckImageSize, HtmlUnknownTarget, HtmlUnknownAnchorTarget -->
<p-dialog [(visible)]="displayAbout" class="about-dialog" [modal]="true" [dismissableMask]=true
    [style]="{'max-width': '300px'}">
  <div class="about-dialog">
    <img src="/assets/resources/svc_lunar_eclipse.png" alt="lunar eclipse" width="64" height="64">
    <h2>Sky View Café NG</h2>
    Version 1.18.0<br>
    <span class="tz-data">tz-data: {{Timezone.version}}</span><br>
    Copyright © 2016-2023 Kerry Shetline.
  </div>
</p-dialog>
<svc-native-date-time-dialog [(visible)]="app.showNativeInputDialog"></svc-native-date-time-dialog>
<svc-preferences-dialog [(visible)]="displayPreferences"></svc-preferences-dialog>
<div class="all-panels">
  <div class="main-app-panel">
    <div class="banner-backdrop">
      <div class="banner">
        <div><a href="/assets/help/" target="_blank"><i class="fas fa-question-circle"></i><br>help</a></div>
        <div><a href="/assets/about.html" target="_blank"><i class="fas fa-info-circle"></i><br>about</a></div>
        <div><a href="https://www.facebook.com/groups/152276391475378/" target="_blank"><i class="fab fa-facebook"></i><br>discuss</a></div>
        <div><a href="https://github.com/kshetline/sky-view-cafe-astronomy/" target="_blank"><i class="fas fa-code"></i><br>code</a></div>
        <div><a href="/assets/donate.html" target="_blank"><i class="fas fa-money-check-alt"></i><br>donate</a></div>
      </div>
    </div>
    <div class="top-panel">
      <div class="time-panel">
        <div class="time-panel-row">
          <div class="time-panel-item">
            <button pButton type="button" (click)="setToNow()" [disabled]="trackTime" label="Now"></button>
          </div>
          <div class="time-panel-item">
            <tbw-time-editor [(ngModel)]="time"
                [options]="clockOptions"
                [timezone]="timezone"
                [min]="SVC_MIN_YEAR"
                [max]="SVC_MAX_YEAR"
                [viewOnly]="trackTime"
                [promptForNative]="promptForNative"
                [nativeDateTime]="nativeDateTime"
                [gregorianChangeDate]="gcDate"></tbw-time-editor>
            <tbw-time-editor *ngIf="app.clockFloating"
                id="floating-clock"
                floating
                wideSpinner
                showCloser
                (close)="closeFloatingClock()"
                [(position)]="clockPosition"
                [style.font-size]="floatingClockFontSize + 'em'"
                [(ngModel)]="time"
                [options]="clockOptions"
                [timezone]="timezone"
                [min]="SVC_MIN_YEAR"
                [max]="SVC_MAX_YEAR"
                [viewOnly]="trackTime"
                [gregorianChangeDate]="gcDate"></tbw-time-editor>
          </div>
          <div class="time-panel-item">
            <span #calendarTarget style="position: relative; left: -1.2em; top: 2px"></span>
            <p-overlayPanel #calendarPopup class="calendar-popup">
              <tbw-calendar #calendar
                  [(ngModel)]="date"
                  [timezone]="timezone"
                  [firstDay]="firstDay"
                  [gregorianChangeDate]="gcDate"
                  showDst
                  [minYear]="SVC_MIN_YEAR"
                  [maxYear]="SVC_MAX_YEAR"
                  [backgroundDecorator]="getBackground"
                  (dayClick)="calendarPopup.hide()"></tbw-calendar>
            </p-overlayPanel>
            <ks-icon-button [disabled]="trackTime" icon="far fa-calendar-alt fa-lg"
                            (click)="calendarPopup.toggle($event, calendarTarget); calendar.reset()"></ks-icon-button>&nbsp;
          </div>
          <div class="time-panel-item">
            <p-checkbox [(ngModel)]="trackTime" binary="true" label="Track current time"></p-checkbox>
          </div>
          <div class="time-panel-item">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div class="time-panel-item">
            <svc-event-navigator [disabled]="trackTime"></svc-event-navigator>
          </div>
        </div>
        <div class="time-panel-row">
          <div class="time-panel-item">&nbsp;</div>
          <div class="time-panel-caption">{{clockCaption}}</div>
          <div class="time-panel-item">&nbsp;</div>
          <div class="time-panel-item">&nbsp;</div>
          <div class="time-panel-item">&nbsp;</div>
          <div class="time-panel-caption">previous/next event</div>
        </div>
      </div>
      <div class="more-popup">
        <p-menu #moremenu [popup]="true" [model]="moreItems"></p-menu>
        <button type="button" pButton icon="fas fa-list" label="More..." (click)="moremenu.toggle($event)"></button>
      </div>
    </div>
    <div class="lower-panel">
      <ks-tab-view class="tabs" (change)="tabChanged($event)" [activeTab]="selectedTab">
        <ks-tab header="Sky">
          <svc-sky-view></svc-sky-view>
        </ks-tab>
        <ks-tab header="Ecliptic">
          <svc-ecliptic-view></svc-ecliptic-view>
        </ks-tab>
        <ks-tab header="Orbits">
          <svc-orbit-view></svc-orbit-view>
        </ks-tab>
        <ks-tab header="Moons/GRS">
          <svc-moons-view></svc-moons-view>
        </ks-tab>
        <ks-tab header="Insolation">
          <svc-insolation-view></svc-insolation-view>
        </ks-tab>
        <ks-tab header="Map">
          <svc-map-view></svc-map-view>
        </ks-tab>
        <ks-tab header="Calendar">
          <svc-calendar-view></svc-calendar-view>
        </ks-tab>
        <ks-tab header="Time">
          <svc-time-view></svc-time-view>
        </ks-tab>
        <ks-tab header="Tables">
          <svc-table-view></svc-table-view>
        </ks-tab>
        <svc-eclipse-circumstances [style.visibility]="showEclipseCircumstances ? 'visible' : 'hidden'"></svc-eclipse-circumstances>
      </ks-tab-view>
      <div id="locationAndOptions">
        <svc-location-settings style="flex: 0 0 auto"></svc-location-settings>
        <svc-options-panel style="flex: 100%"></svc-options-panel>
      </div>
    </div>
  </div>
  <div id="quickTips" class="help-panel">
    <h3>Quick Tips</h3>
    <p>Please read the <a href="/assets/help/" target="_blank">Help</a> page. There's plenty of useful information there, and a
      lot that <em>Sky View Café</em> can do that you'll discover.</p>

    <h3>Time</h3>
    <p>Click on the clock in the upper left corner of the app to change the time. You can type in the time, or click on
       a digit and use the keyboard arrow keys or on-screen arrows to adjust the value.</p>
    <p>The clock uses 24-hour time. 0 is midnight, 12 is noon, 13 is 1 PM, 23 is 11 PM. The § symbol indicates Daylight
       Saving Time.</p>
    <p>Find out more about: <a href="/assets/help/#time" target="_blank">time</a>, <a href="/assets/help/#zone" target="_blank">time
       zones</a>, and years <a href="/assets/help/#bce" target="_blank">BCE</a>...</p>

    <h3>Location</h3>
    <p>If <em>Sky View Café</em> does not automatically set your desired location, you can use the <strong>Find...</strong> button to look for cities
       and towns. You can also click on the latitude and longitude fields to manually set a new observing location. Make sure to also pick the correct
       matching timezone for your location.<p>
    <p>Coming back later? Click the <strong>Save...</strong> button to store locations for your next visit. (Cookies must be enabled.)

    <hr>

    <p><a href="/assets/help/#eastleft" target="_blank">Why is East on the left?</a></p>

    <p><a href="https://legacy.skyviewcafe.com/skyview.php" target="_blank">Looking for the original Sky View Café Java applet?</a></p>
  </div>
</div>
<p-toast key="general" position="top-right"></p-toast>
