<div #canvasWrapper class="canvasWrapper">
  <div class="canvasAnchor">
    <canvas #skyCanvas (window:resize)="onResize()"
            (mousedown)="onMouseDown($event)"
            (mousemove)="onMouseMove($event)"
            (mouseup)="onMouseUp($event)"
            [style.cursor]="cursor"
    ></canvas>
  </div>
</div>
<ks-marquee class="marquee" [text]="marqueeText"></ks-marquee>
