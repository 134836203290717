<p-dialog header="Preferences" [(visible)]="visible" ksSizer="37em" (keyup)="onKey($event)">
  <div class="settings-grid">
    <div class="settings-row">
      <div class="settings-label">
        Start up:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="startUpOptions" [(ngModel)]="startUpOption"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Default location:
      </div>
      <div class="settings-value">
        <ks-dropdown #defaultLocationDropdown [options]="locations" [(ngModel)]="defaultLocation"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Default view:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="tabs" [(ngModel)]="defaultTab"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Input clock style:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="clockStyles" [(ngModel)]="clockStyle"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Enter latitude and longitude:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="latLongStyles" [(ngModel)]="latLongStyle"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Azimuth:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="azimuths" [(ngModel)]="northAzimuth"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        <label for="twilightInput">Twilight begin/end events:</label>
      </div>
      <div class="settings-value twilight">
        <ks-dropdown [options]="twilightOptions" [(ngModel)]="twilightByDegrees" ksSizer="27em"></ks-dropdown>
        <input type="text" [(ngModel)]="twilightValue" pInputText class="twilight-input form-control"
               id="twilightInput" required minlength="1" maxlength="3" pattern="\d{1,3}" (input)="onTwilightChange($event.target)"/>
      </div>
    </div>
    <div *ngIf="!formValid" class="settings-row">
      <div class="settings-label"></div>
      <div class="settings-value error-caption">
        {{ invalidMessage }}
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Calendar:
      </div>
      <div class="calendar">
        <ks-dropdown [options]="calendarOptions" [(ngModel)]="calendarOption" ksSizer="23em"></ks-dropdown>
        <tbw-time-editor
          [options]="'iso_date'"
          timezone="UTC"
          gregorianChangeDate="G"
          min="300"
          max="3900"
          [disabled]="gcdDisabled"
          [blank]="!gcdVisible"
          [(date)]="gcdValue"></tbw-time-editor>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        Ink saver:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="inkSaverOptions" [(ngModel)]="inkSaver"></ks-dropdown>
      </div>
    </div>
    <div *ngIf="showDateTimeOptions" class="settings-row">
      <div class="settings-label">
        Date/time input:
      </div>
      <div class="settings-value">
        <ks-dropdown [options]="dateTimeOptions" [(ngModel)]="nativeDateTime"></ks-dropdown>
      </div>
    </div>
    <div class="settings-row">
      <div class="settings-label">
        &nbsp;
      </div>
      <div class="settings-value checkbox-wrapper">
        <p-checkbox [(ngModel)]="resetWarnings" binary="true" label="Reset warnings"></p-checkbox>
      </div>
    </div>
  </div>
  <p-footer>
    <div *ngIf="clockStyle === ISO_SEC || clockStyle === LOCAL_SEC" class="time-accuracy-note">See notes on time accuracy!</div>
    <div class="p-dialog-buttonpane ui-widget p-clearfix">
      <button type="button" pButton icon="far fa-window-close" (click)="visible=false" label="Cancel"></button>
      <button type="button" pButton icon="fas fa-check" (click)="setPreferences()" label="OK" [disabled]="!formValid"></button>
    </div>
  </p-footer>
</p-dialog>
