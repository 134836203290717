<div>
  <ks-dropdown [options]="viewTypes" [(ngModel)]="viewType"></ks-dropdown>
  <ks-dropdown [options]="skyColors" [(ngModel)]="skyColor"></ks-dropdown>
  <p-dropdown [options]="additionals" [(ngModel)]="additional" [filter]="true" filterBy="search"
    [resetFilterOnHide]="true" emptyFilterMessage="No match"
    [disabled]="!asteroidsReady" class="constrained-dropdown" [autoDisplayFirst]="false"></p-dropdown>
  <ks-checkbox [(ngModel)]="refraction" binary="true" label="Refraction effect"
    [disabled]="viewType > 6"></ks-checkbox>
  <ks-checkbox [(ngModel)]="celestial" binary="true" label="Celestial grid"></ks-checkbox>
  <ks-checkbox [(ngModel)]="ecliptic" binary="true" label="Ecliptic grid"></ks-checkbox>
  <ks-checkbox [(ngModel)]="pathOfSun" binary="true" label="Path of Sun, ±12h"
    [disabled]="viewType > 6"></ks-checkbox>
  <ks-checkbox [(ngModel)]="pathOfMoon" binary="true" label="Path of Moon, ±12h"
    [disabled]="viewType > 6"></ks-checkbox>
  <ks-checkbox [(ngModel)]="brightenStars" binary="true" label="Brighten stars"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showConstellations" binary="true" label="Constellations"></ks-checkbox>
  <ks-checkbox [(ngModel)]="enlargeSunMoon" binary="true" label="Enlarge Sun/Moon"
    [disabled]="viewType > 6"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showMilkyWay" binary="true" label="Milky Way"
    [disabled]="viewType > 6"></ks-checkbox>
</div>
<p-menu #menu [popup]="true" [model]="namesCategories"></p-menu>
<br>
<button type="button" pButton icon="fas fa-list" label="Show Names..." (click)="menu.toggle($event)"></button>
