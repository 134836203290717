<div>
  <div style="align-self: center">Change location to...</div>
  <button pButton type="button" label="Subsolar point" (click)="goToSubsolarPoint()"></button>
  <button pButton type="button" label="Greatest eclipse" (click)="goToEclipseCenter()" [disabled]="!eclipseActive"></button>
  <hr>
  <ks-dropdown [options]="mapTypes" [(ngModel)]="mapType"></ks-dropdown>
  <ks-checkbox [(ngModel)]="showDayNight" binary="true" label="Show day/night"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showTimezones" binary="true" label="Show timezones{{zonesVersion}}" [disabled]="timezonesDisabled"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showEclipseShadows" binary="true" label="Show eclipse shadows"></ks-checkbox>
  <ks-checkbox [(ngModel)]="showMarkers" binary="true" label="Show location markers"></ks-checkbox>
  <ks-checkbox [(ngModel)]="blink" binary="true" label="Blink location markers" [disabled]="!showMarkers"></ks-checkbox>
  <div [hidden]="!showMarkers" class="marker-legend">
    <img src="/assets/resources/marker_location.gif" alt="subsolar point"> Observer location<br>
    <img src="/assets/resources/marker_subsolar.gif" alt="subsolar point"> Subsolar point<br>
    <img src="/assets/resources/marker_eclipse.gif" alt="subsolar point"> Eclipse center
  </div>
</div>
