<div class="wrapper">
  <div class="inc-dec-panel">
    <div class="inc-dec-pair">
      <button pButton type="button" icon="fas fa-chevron-left" (click)="changeMonth(-1)"
        (touchstart)="onTouchStart($event, -1)" (touchend)="stopClickTimer()"
        (mousedown)="onMouseDown(-1, $event)" (mouseup)="stopClickTimer()" (mouseleave)="stopClickTimer()"></button>
      <span>Month</span>
      <button pButton type="button" icon="fas fa-chevron-right" (click)="changeMonth(1)"
        (touchstart)="onTouchStart($event, 1)" (touchend)="stopClickTimer()"
        (mousedown)="onMouseDown(1, $event)" (mouseup)="stopClickTimer()" (mouseleave)="stopClickTimer()"></button>
    </div>
    <div class="inc-dec-pair">
      <button pButton type="button" icon="fas fa-chevron-left" (click)="changeMonth(-12)"
        (touchstart)="onTouchStart($event, -12)" (touchend)="stopClickTimer()"
        (mousedown)="onMouseDown(-12, $event)" (mouseup)="stopClickTimer()" (mouseleave)="stopClickTimer()"></button>
      <span>Year</span>
      <button pButton type="button" icon="fas fa-chevron-right" (click)="changeMonth(12)"
        (touchstart)="onTouchStart($event, 12)" (touchend)="stopClickTimer()"
        (mousedown)="onMouseDown(12, $event)" (mouseup)="stopClickTimer()" (mouseleave)="stopClickTimer()"></button>
    </div>
  </div>
  <hr>
  <ks-dropdown [options]="firstDays" [(ngModel)]="firstDay"></ks-dropdown>
  <div class="first-day-caption">first day of week</div>
  <ks-checkbox [(ngModel)]="keyMoonPhases" binary="true" label="Key Moon phases"></ks-checkbox>
  <ks-checkbox [(ngModel)]="equisolstice" binary="true" label="Equinox/Solstice"></ks-checkbox>
  <ks-checkbox [(ngModel)]="dailyDaylight" binary="true" label="Daily daylight"></ks-checkbox>
  <ks-checkbox [(ngModel)]="dailyMoonPhase" binary="true" label="Daily Moon phase"></ks-checkbox>
  <ks-dropdown [options]="eventTypes" [(ngModel)]="eventType" scrollHeight="264px"></ks-dropdown>
  <ks-checkbox [(ngModel)]="includeTransits" binary="true" label="Include transit time"></ks-checkbox>
</div>
